import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Section, Columns } from "../components/Containers"
import { Image, Text } from "../components/Core"
import { Button } from "../components/Button"

import "../css/theme/_single-job-listing.scss"
import Seamless from "../components/Seamless"

const SingleJobListing = ({ pageContext, location, data }) => {
  const { language } = pageContext
  const { metaTitle, metaDescription, hero, description, formUrl } =
    data.allJobListingsJson.nodes[0]

  return (
    <Layout navSpacer language={language}>
      <SEO
        robots="noindex"
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        lang={language}
      />

      <div className="sjl relative">
        <Section zeroBottomDesktop>
          <Columns>
            <div className="column is-2"></div>
            <div className="column">
              {/* <Image
                wrapperClassName="is-hidden-desktop"
                publicId={hero.image}
              /> */}
              <div className="sjl__hero">
                <div className="sjl__hero--content px-40--mobile">
                  <Text as="h1" text={hero.heading} />

                  <div className="sjl__hero--tags">
                    {hero.location && (
                      <div>
                        <h3>Location</h3>
                        <p className="mt-0 mb-0">{hero.location}</p>
                      </div>
                    )}
                    {hero.specialty && (
                      <div>
                        <h3>Specialty</h3>
                        <p className="mt-0 mb-0">{hero.specialty}</p>
                      </div>
                    )}
                  </div>
                </div>

                <Image
                  wrapperClassName="sjl__hero--image"
                  publicId={hero.image}
                />
              </div>
            </div>
          </Columns>

          <Columns sideColumnsSize={2} className="mt-3 mb-3">
            <div className="column">
              <div className="sjl__about px-40--mobile">
                <h2>Join Our Team!</h2>
                <Text className="mt-0" as="p" text={hero.blurb} />
              </div>
            </div>
          </Columns>
        </Section>

        <Section colorBack className="sjl__requirements rounded-top">
          <Columns sideColumnsSize={2}>
            <div className="column">
              <div className="sjl__requirements-grid">
                <div>
                  {description.map(desc => (
                    <div className="sjl__desc px-40--mobile" key={desc.heading}>
                      <Text as="h3" text={desc.heading} />
                      <Text text={desc.blurb} />
                    </div>
                  ))}
                </div>

                <div>
                  <h3 className="mb-1 px-40--mobile">Application Form</h3>
                  <Seamless src={formUrl} />
                </div>
              </div>
            </div>
          </Columns>
        </Section>
      </div>
    </Layout>
  )
}

export const jobListingBQuery = graphql`
  query jobListing($title: String!) {
    allJobListingsJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        hero {
          heading
          blurb
          image
          location
          specialty
        }
        formUrl
        description {
          heading
          blurb
        }
      }
    }
  }
`

export default SingleJobListing
